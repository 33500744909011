<template>
  <div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>

export default {
  name: 'UserContainer',
  beforeMount() {
    if (this.$route.name === 'UserContainer') {
      this.$router.replace({name: 'Users' });
    }
  }
}
</script>
